exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-posts-js": () => import("./../../../src/pages/all-posts.js" /* webpackChunkName: "component---src-pages-all-posts-js" */),
  "component---src-pages-communicare-js": () => import("./../../../src/pages/communicare.js" /* webpackChunkName: "component---src-pages-communicare-js" */),
  "component---src-pages-communicare-privacy-policy-js": () => import("./../../../src/pages/communicare-privacy-policy.js" /* webpackChunkName: "component---src-pages-communicare-privacy-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-loon-rush-privacy-policy-js": () => import("./../../../src/pages/loon-rush-privacy-policy.js" /* webpackChunkName: "component---src-pages-loon-rush-privacy-policy-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-tichu-counter-js": () => import("./../../../src/pages/privacy-policy/tichu-counter.js" /* webpackChunkName: "component---src-pages-privacy-policy-tichu-counter-js" */),
  "component---src-posts-post-new-mdx": () => import("./../../../src/posts/post-new.mdx" /* webpackChunkName: "component---src-posts-post-new-mdx" */),
  "component---src-posts-post-one-mdx": () => import("./../../../src/posts/post-one.mdx" /* webpackChunkName: "component---src-posts-post-one-mdx" */),
  "component---src-posts-post-two-mdx": () => import("./../../../src/posts/post-two.mdx" /* webpackChunkName: "component---src-posts-post-two-mdx" */)
}

